import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  useTheme,
  Tooltip,
  Button,
  alpha,
  styled,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  ListItemIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GitHubIcon from '@mui/icons-material/GitHub';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TranslateIcon from '@mui/icons-material/Translate';
import SchoolIcon from '@mui/icons-material/School';
import ArticleIcon from '@mui/icons-material/Article';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${alpha(theme.palette.primary.dark, 0.95)} 100%)`,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .12)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url(/images/patterns/islamic-pattern.png) repeat',
    opacity: 0.1,
    pointerEvents: 'none',
  }
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  background: `linear-gradient(45deg, ${theme.palette.common.white} 30%, ${alpha(theme.palette.common.white, 0.8)} 90%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
  cursor: 'pointer',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  margin: theme.spacing(0, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
  textTransform: 'none',
  fontWeight: 500,
}));

interface HeaderProps {
  onLanguageChange?: () => void;
}

const Header: React.FC<HeaderProps> = ({ onLanguageChange }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toolsAnchorEl, setToolsAnchorEl] = useState<null | HTMLElement>(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.ResizeObserver) {
        const resizeObserver = new ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
              return;
            }
          });
        });

        const appBar = document.querySelector('.MuiAppBar-root');
        if (appBar) {
          resizeObserver.observe(appBar);
        }

        return () => {
          resizeObserver.disconnect();
        };
      }
    };

    resizeHandler();
    return () => {
      // Cleanup
    };
  }, []);

  const getTools = () => [
    { name: t('nav.keyboard'), path: '/keyboard', icon: <KeyboardIcon /> },
    { name: t('nav.editor'), path: '/editor', icon: <EditIcon /> },
    { name: t('nav.dictionary'), path: '/dictionary', icon: <MenuBookIcon /> },
    { name: t('nav.transliteration'), path: '/transliteration', icon: <TranslateIcon /> },
  ];

  const getResources = () => [
    { name: t('nav.tutorials'), path: '/tutorials', icon: <SchoolIcon /> },
    { name: t('nav.blog'), path: '/blog', icon: <ArticleIcon /> },
  ];

  const tools = getTools();
  const resources = getResources();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setToolsAnchorEl(event.currentTarget);
  };

  const handleResourcesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setResourcesAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAllMenusClose = () => {
    setAnchorEl(null);
    setToolsAnchorEl(null);
    setResourcesAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleAllMenusClose();
  };

  return (
    <StyledAppBar position="static">
      <Toolbar 
        sx={{ 
          justifyContent: 'space-between', 
          py: 1,
          direction: 'ltr' // Force left-to-right layout
        }}
      >
        {/* Logo and Title Section */}
        <Box display="flex" alignItems="center" sx={{ order: 1 }}>
          <LogoText variant="h1" sx={{ mx: 2 }} onClick={() => navigate('/')}>
            نیا اردو
          </LogoText>
        </Box>

        {/* Actions Section */}
        <Box display="flex" alignItems="center" sx={{ order: 2 }}>
          {/* Menu Items for larger screens */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ActionButton onClick={() => handleNavigation('/software')}>
              {t('nav.software')}
            </ActionButton>
            
            <ActionButton
              onClick={handleToolsMenu}
              sx={{ 
                color: 'inherit',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                }
              }}
            >
              {t('nav.tools')}
            </ActionButton>
            <Menu
              anchorEl={toolsAnchorEl}
              open={Boolean(toolsAnchorEl)}
              onClose={handleAllMenusClose}
              disableScrollLock
              keepMounted
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{ direction: 'ltr' }}
            >
              {tools.map((tool) => (
                <MenuItem
                  key={tool.path}
                  onClick={() => handleNavigation(tool.path)}
                  sx={{ direction: i18n.dir() }}
                >
                  <ListItemIcon sx={{ color: 'primary.main' }}>{tool.icon}</ListItemIcon>
                  {tool.name}
                </MenuItem>
              ))}
            </Menu>

            <ActionButton
              onClick={handleResourcesMenu}
              sx={{ 
                color: 'inherit',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                }
              }}
            >
              {t('nav.resources')}
            </ActionButton>
            <Menu
              anchorEl={resourcesAnchorEl}
              open={Boolean(resourcesAnchorEl)}
              onClose={handleAllMenusClose}
              disableScrollLock
              keepMounted
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{ direction: 'ltr' }}
            >
              {resources.map((resource) => (
                <MenuItem
                  key={resource.path}
                  onClick={() => handleNavigation(resource.path)}
                  sx={{ direction: i18n.dir() }}
                >
                  <ListItemIcon sx={{ color: 'primary.main' }}>{resource.icon}</ListItemIcon>
                  {resource.name}
                </MenuItem>
              ))}
              <MenuItem 
                onClick={() => handleNavigation('/docs')}
                sx={{ direction: i18n.dir() }}
              >
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <MenuBookIcon />
                </ListItemIcon>
                {t('nav.documentation')}
              </MenuItem>
            </Menu>

            <ActionButton onClick={() => handleNavigation('/about')}>
              {t('nav.about')}
            </ActionButton>
          </Box>

          <ActionButton
            startIcon={<HelpOutlineIcon />}
            onClick={() => handleNavigation('/help')}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            {t('nav.help')}
          </ActionButton>

          <Tooltip title={t('nav.changeLanguage')}>
            <IconButton
              color="inherit"
              onClick={onLanguageChange}
              sx={{ ml: 1 }}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('nav.viewOnGithub')}>
            <IconButton
              color="inherit"
              component="a"
              href="https://github.com/yourusername/newurdu"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ml: 1 }}
            >
              <GitHubIcon />
            </IconButton>
          </Tooltip>

          {/* Hamburger menu for mobile */}
          <IconButton
            color="inherit"
            onClick={handleMenuOpen}
            sx={{ display: { xs: 'flex', md: 'none' }, ml: 1 }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Mobile Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            }
          }}
          disableScrollLock
          keepMounted
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => handleNavigation('/software')}>
            {t('nav.software')}
          </MenuItem>
          
          {/* Tools Section */}
          <Typography variant="subtitle2" sx={{ px: 2, py: 1, opacity: 0.7 }}>
            {t('nav.tools')}
          </Typography>
          {tools.map((tool) => (
            <MenuItem
              key={tool.path}
              onClick={() => handleNavigation(tool.path)}
            >
              <ListItemIcon sx={{ color: 'white' }}>{tool.icon}</ListItemIcon>
              {tool.name}
            </MenuItem>
          ))}
          
          <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          
          {/* Resources Section */}
          <Typography variant="subtitle2" sx={{ px: 2, py: 1, opacity: 0.7 }}>
            {t('nav.resources')}
          </Typography>
          {resources.map((resource) => (
            <MenuItem
              key={resource.path}
              onClick={() => handleNavigation(resource.path)}
            >
              <ListItemIcon sx={{ color: 'white' }}>{resource.icon}</ListItemIcon>
              {resource.name}
            </MenuItem>
          ))}
          <MenuItem onClick={() => handleNavigation('/docs')}>
            <ListItemIcon sx={{ color: 'white' }}>
              <MenuBookIcon />
            </ListItemIcon>
            {t('nav.documentation')}
          </MenuItem>
          
          <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          
          <MenuItem onClick={() => handleNavigation('/about')}>
            {t('nav.about')}
          </MenuItem>
          <MenuItem onClick={() => handleNavigation('/help')}>
            {t('nav.help')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
