import React, { lazy, Suspense, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const Software = lazy(() => import('./pages/Software'));
const About = lazy(() => import('./pages/About'));
const Keyboard = lazy(() => import('./pages/Keyboard'));
const Dictionary = lazy(() => import('./pages/Dictionary'));
const Editor = lazy(() => import('./pages/Editor'));
const Transliteration = lazy(() => import('./pages/Transliteration'));
const Blog = lazy(() => import('./pages/Blog'));
const Contact = lazy(() => import('./pages/Contact'));
const Tutorials = lazy(() => import('./pages/Tutorials'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const Documentation = lazy(() => import('./pages/Documentation'));
const Help = lazy(() => import('./pages/Help'));
const BlogPost = lazy(() => import('./pages/BlogPost'));

const Loading = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
);

function App() {
  const { i18n } = useTranslation();

  const handleLanguageChange = useCallback(() => {
    const newLang = i18n.language === 'ur' ? 'en' : 'ur';
    i18n.changeLanguage(newLang);
    document.dir = newLang === 'ur' ? 'rtl' : 'ltr';
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ 
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.background.default 
        }}>
          <Header onLanguageChange={handleLanguageChange} />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/software" element={<Software />} />
                <Route path="/about" element={<About />} />
                <Route path="/keyboard" element={<Keyboard />} />
                <Route path="/dictionary" element={<Dictionary />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/transliteration" element={<Transliteration />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/tutorials" element={<Tutorials />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/docs" element={<Documentation />} />
                <Route path="/docs/:section" element={<Documentation />} />
                <Route path="/help" element={<Help />} />
              </Routes>
            </Suspense>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default React.memo(App);
