import React from 'react';
import { Box, Container, Grid, Typography, Link, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const footerSections = [
    {
      title: t('footer.tools'),
      items: [
        { name: t('footer.keyboard'), link: '/keyboard' },
        { name: t('footer.editor'), link: '/editor' },
        { name: t('footer.dictionary'), link: '/dictionary' },
        { name: t('footer.transliteration'), link: '/transliteration' },
      ],
    },
    {
      title: t('footer.resources'),
      items: [
        { name: t('footer.tutorials'), link: '/tutorials' },
        { name: t('footer.documentation'), link: '/docs' },
        { name: t('footer.blog'), link: '/blog' },
      ],
    },
    {
      title: t('footer.company'),
      items: [
        { name: t('footer.about'), link: '/about' },
        { name: t('footer.contact'), link: '/contact' },
        { name: t('footer.privacy'), link: '/privacy' },
        { name: t('footer.terms'), link: '/terms' },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.paper,
        pt: 6,
        pb: 3,
        mt: 'auto',
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              NewUrdu
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {t('footer.description')}
            </Typography>
          </Grid>

          {/* Footer Sections */}
          {footerSections.map((section, index) => (
            <Grid item xs={6} md={2} key={index}>
              <Typography variant="subtitle1" color="text.primary" gutterBottom fontWeight="bold">
                {section.title}
              </Typography>
              <Stack spacing={1}>
                {section.items.map((item, itemIndex) => (
                  <Link
                    key={itemIndex}
                    component={RouterLink}
                    to={item.link}
                    color="text.secondary"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'none',
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Copyright */}
        <Box sx={{ pt: 2 }}>
          <Typography variant="body2" color="text.secondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link component={RouterLink} to="/" color="inherit">
              NewUrdu
            </Link>
            {'. '}
            {t('footer.rights')}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
            {t('footer.developedBy')}{' '}
            <Link
              href="https://digitalcloud.pk"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              digitalcloud.pk
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
